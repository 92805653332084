import React from 'react'
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";

import WebsiteMeta from '../common/meta/WebsiteMeta'


import { Layout } from '../common'

/**
* Single brain/wiki note view (/wiki/:slug)
*
* This file renders a single brain note and loads all the content.
*
*/
const BrainNote = ({ note }) => {
    let references = [];
    let referenceBlock;
    if (note.inboundReferencePreviews != null) {
        references = note.inboundReferencePreviews.flatMap((ref) => 
        ( ref.source === 'wiki' ) ? [] :
        (
            <li>
                <a href={ref.source}>{ref.title}</a>
                <br />
                <div dangerouslySetInnerHTML={{ __html: ref.previewHtml }} />
            </li>
        ));
      if (references.length > 0) {
        referenceBlock = (
          <>
            <h2>Linked References</h2>
            <ul>{references}</ul>
          </>
        );
      }
    }
    return (
    <>
        <WebsiteMeta
            data={{}}
            canonical={note.slug}
            title={note.title}
            description=""
            type="WebSite"
        />
        <Layout>
            <div className="container">
                <article className="content">
                    <section className="post-full-content">
                    <div id="brainNote">
                        <h1>{note.title}</h1>

                        <section className="content-body">
                            <MDXRenderer>{note.childMdx.body}</MDXRenderer>
                        </section>
                    </div>
                    </section>
                </article>
                
            </div>
            <div id="brainNoteReferences">
                <div className="content">
                    <section className="post-full-content">
                        {referenceBlock}
                    </section>
                </div>
            </div>
            
        </Layout>
    </>
      
    );
  };
  
  export default BrainNote;